import useApi from '../../../services/useApi'
import {TicketDetailsContext} from '../context'
import {useContext} from 'react'

const useGetTicketDetails = () => {
  const Api = useApi()
  const {projectId, ticketId} = useContext(TicketDetailsContext)

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/cards/${ticketId}`,
    {
      queryId: 'project-ticket-details',
      isCaching: false,
    },
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return {
    ticketDetails: response?.data?.[0] || [],
    isLoading: isFetching,
  }
}

export default useGetTicketDetails

import {createContext} from 'react'

export const TicketDetailsContext = createContext({
  hasProjectPermission: false,
  projectId: null as any,
  ticketId: null as any,
  ticketIdentifier: '' as any,
  projectName: '',
  projectStatus: '',
  projectDetails: {} as any,
  isLoading: false,
})

export const TicketDetailsPageContext = createContext({
  hasTicketWritePermission: false,

  projectStatus: '',
  projectName: '',
  projectId: null as any,
  ticketId: '',
  cardIdentifier: '',

  ticketDetails: [] as any,
  isLoadingTicketDetails: false,

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  updateTicket: (data: any, dirtyFields: any, setError: Function) => {},
  deleteTicket: () => {},
  isLoadingTicketOperation: false,

  createComment: (commentData: any) => {},
  editComment: (commentId: any, data: any) => {},
  deleteComment: (commentId: any) => {},
  isLoadingCommentOperation: false,

  customFieldsList: [],
  isLoadingCustomFieldsList: false,

  commentsList: [],
  isLoadingCommentsList: false,

  updateCustomField: (ticketCFieldData: any) => {},
  isLoadingCFieldsOperation: false,

  isTicketEditable: false,
  setIsTicketEditable: (value: boolean) => {},

  ticketActivitiesHistory: [] as any[],
  isLoadingTicketActivitiesHistory: false,

  pagination: null as any,
  onLoadMore: (nextPage: number) => {},

  ticketSpentTimeHistory: [] as any[],
  SpentTimePagination: null as any,
  isSpentTimeLoading: false,
  onLoadMoreSpentTime: (nextPage: number) => {},

  deleteSpentTime: (id: any) => {},
  updateSpentTime: (data: any, dirtyFields: any, setError: Function, id: any) => {},
  isTimeLogOperationLoading: false,
})

import useApi from '../../../services/useApi'
import {useContext, useState} from 'react'
import {TicketDetailsContext} from '../context'

export default function useTicketOperations() {
  const Api = useApi()
  const {projectId, ticketId} = useContext(TicketDetailsContext)
  const [isLoading, setLoading] = useState(false)

  async function updateTicket(data: any, dirtyFields: any, setError: Function) {
    return await Api.patch(`/agile/projects/${projectId}/cards/${ticketId}`, data, {
      dirtyFields: dirtyFields,
      setLoading: setLoading,
      successMessage: data?.spent_time && 'Timelog added successfully',
      refetch: ['project-ticket-details', 'ticket-activities-history', 'ticket-spentTime-time-log'],
      setUseFormError: setError,
    })
  }

  async function deleteTicket() {
    return await Api.delete(
      `/agile/projects/${projectId}/cards/${ticketId}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Ticket deleted successfully',
        navigate: `/projects/all-projects/${projectId}?view=agile-board`,
      }
    )
  }

  return {
    updateTicket,
    deleteTicket,
    isLoading,
  }
}

import {useContext, useState} from 'react'
import useApi from '../../../services/useApi'
import {TicketDetailsContext} from '../context'

function useCommentOperations() {
  const Api = useApi()
  const {ticketId} = useContext(TicketDetailsContext)
  const [isLoading, setLoading] = useState(false)

  async function createComment(payload: any) {
    return Api.post(`/agile/projects/cards/${ticketId}/comments`, payload, {
      setLoading: setLoading,
      successMessage: 'Comment added successfully',
      refetch: ['ticket-comments-list', 'ticket-activities-history'],
    })
  }

  async function editComment(commentId: any, payload: any) {
    return Api.put(`/agile/projects/cards/${ticketId}/comments/${commentId}`, payload, {
      setLoading: setLoading,
      successMessage: 'Comment updated successfully',
      refetch: ['ticket-comments-list', 'ticket-activities-history'],
    })
  }

  async function deleteComment(commentId: any) {
    return Api.delete(
      `/agile/projects/cards/${ticketId}/comments/${commentId}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Comment deleted successfully',
        refetch: ['ticket-comments-list', 'ticket-activities-history'],
      }
    )
  }
  return {
    createComment,
    editComment,
    deleteComment,
    isLoading,
  }
}

export default useCommentOperations

// Function to convert
// single digit input

import {isEmpty} from './common'

// to two digits
const formatData = (input: number) => {
  if (input > 9) {
    return input
  } else return `0${input}`
}

// Function to convert
// 24 Hour to 12 Hour clock
const formatHour = (input: number) => {
  if (input > 12) {
    return input - 12
  }
  return input
}

export const format24Hour = (currDate: any, isDate: boolean) => {
  const date = new Date(currDate)
  const format = {
    dd: formatData(date.getDate()),
    mm: formatData(date.getMonth() + 1),
    yyyy: date.getFullYear(),
    HH: formatData(date.getHours()),
    hh: formatData(formatHour(date.getHours())),
    MM: formatData(date.getMinutes()),
    SS: formatData(date.getSeconds()),
  }

  if (isDate) {
    return `${format?.mm}/${format?.dd}/${format?.yyyy}`
  }

  return `${format?.mm}/${format?.dd}/${format?.yyyy} ${format?.HH}:${format?.MM}`
}

export const format12Hour = (currDate: any, isDate: boolean) => {
  const date = new Date(currDate)
  const format = {
    dd: formatData(date.getDate()),
    mm: formatData(date.getMonth() + 1),
    yyyy: date.getFullYear(),
    HH: formatData(date.getHours()),
    hh: formatData(formatHour(date.getHours())),
    MM: formatData(date.getMinutes()),
    SS: formatData(date.getSeconds()),
  }
  if (isDate) {
    return `${format?.mm}/${format?.dd}/${format?.yyyy}`
  }
  return `${format?.mm}/${format?.dd}/${format?.yyyy} ${format?.hh}:${format?.MM}`
}

export const formatDate = (
  currDate: string | number,
  isDateOnly: boolean,
  isDisableYear: boolean = false,
  isConvertToLocal: boolean = true
) => {
  if (!currDate) return '-'
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const isTimeStamp = typeof currDate === 'number'

  if (isTimeStamp) {
    const date = new Date(currDate * 1000)

    if (isDateOnly) {
      const formattedDate = date.toLocaleString('en-US', {
        year: isDisableYear ? undefined : 'numeric',
        month: 'short',
        day: 'numeric',
      })
      return formattedDate
    } else {
      const formattedDate = date.toLocaleString('en-US', {
        year: isDisableYear ? undefined : 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      return formattedDate
    }
  } else {
    const date = new Date(typeof currDate === 'number' ? currDate * 1000 : currDate)

    if (isConvertToLocal) {
      // Convert to CST
      const cstOffset = -6 * 60 // CST is UTC-6
      const cstTimestamp = date.getTime() + cstOffset * 60 * 1000
      const cstDate = new Date(cstTimestamp)

      const format = {
        dd: formatData(cstDate.getDate()),
        month: months[cstDate.getMonth()],
        yyyy: isDisableYear ? '' : cstDate.getFullYear(),
        hh: formatHourNew(cstDate.getHours()),
        MM: formatDataNew(cstDate.getMinutes()),
        period: formatPeriod(cstDate.getHours()),
      }

      if (isDateOnly) {
        return isDisableYear
          ? `${format.month} ${format.dd}`
          : `${format.dd} ${format.month}  ${format.yyyy}`
      }

      return isDisableYear
        ? `${format.month} ${format.dd}, ${format.hh}:${format.MM} ${format.period}`
        : `${format.month} ${format.dd}, ${format.yyyy}, ${format.hh}:${format.MM} ${format.period}`
    } else {
      const format = {
        dd: formatData(date.getDate()),
        month: months[date.getMonth()],
        yyyy: isDisableYear ? '' : date.getFullYear(),
        hh: formatHourNew(date.getHours()),
        MM: formatDataNew(date.getMinutes()),
        period: formatPeriod(date.getHours()),
      }

      if (isDateOnly) {
        return isDisableYear
          ? `${format.month} ${format.dd}`
          : `${format.dd} ${format.month}  ${format.yyyy}`
      }

      return isDisableYear
        ? `${format.month} ${format.dd}, ${format.hh}:${format.MM} ${format.period}`
        : `${format.month} ${format.dd}, ${format.yyyy}, ${format.hh}:${format.MM} ${format.period}`
    }
  }
}

const formatDataNew = (value: number) => {
  return value.toString().padStart(2, '0')
}

const formatHourNew = (value: number) => {
  return value % 12 === 0 ? '12' : formatData(value % 12)
}

const formatPeriod = (value: number) => {
  return value < 12 ? 'AM' : 'PM'
}

// export const convertTimeStampToDate = (timestamp: number) => {
//   const date = new Date(timestamp * 1000)

//   const formattedDate = date.toLocaleString('en-US', {
//     year: 'numeric',
//     month: 'short',
//     day: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     hour12: true,
//   })

//   return formattedDate
// }

export const MONTHS_LONG = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getCurrentDate = () => {
  const currentDate = new Date()

  const year = currentDate.getFullYear()

  // Months are zero-based
  const month = currentDate.getMonth() + 1

  const day = currentDate.getDate()

  return `${day}-${month}-${year}`
}

export function getFormattedDate(dateString: any) {
  if (!dateString) return null
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  // Adding zero-padding if needed
  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedDay = day < 10 ? `0${day}` : day

  return `${year}-${formattedMonth}-${formattedDay}`
}

// function to convert UTC time string to Local time string with dynamic format
export function oldconvertUTCToLocal(
  utcString: string,
  returnDateOnly: boolean = false,
  isDynamicFormat: boolean = false
) {
  if (isEmpty(utcString)) return
  // Convert the UTC string to a Date object
  const date = new Date(utcString + ' UTC')
  const currentDate = new Date()

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }

  // Adjust options if only the date is required
  if (returnDateOnly) {
    options.hour = undefined
    options.minute = undefined
    options.second = undefined
  }

  // Format the date to local time using user's timezone
  const localTime = new Intl.DateTimeFormat('en-GB', {
    ...options,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(date)

  // Calculate the difference in time
  const diffTime = currentDate.getTime() - date.getTime()
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
  const diffMinutes = Math.floor(diffTime / (1000 * 60))
  const diffSeconds = Math.floor(diffTime / 1000)

  // Return dynamically formatted output if enabled
  if (isDynamicFormat) {
    if (diffHours > 48) {
      return localTime
    } else if (diffHours > 24) {
      return 'Yesterday'
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`
    } else if (diffSeconds > 10) {
      return `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`
    } else {
      return 'Just now'
    }
  }

  return localTime
}

export function convertUTCToLocal(
  utcInput: string | number, // Accepts either a string or a number
  returnDateOnly: boolean = false,
  isDynamicFormat: boolean = false
) {
  if (!utcInput) return

  // Convert the UTC input to a Date object
  const date =
    typeof utcInput === 'number'
      ? new Date(utcInput * 1000) // If it's a timestamp, convert it
      : new Date(utcInput + ' UTC') // Otherwise, assume it's a UTC date string

  const currentDate = new Date()

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }

  // Adjust options if only the date is required
  if (returnDateOnly) {
    options.hour = undefined
    options.minute = undefined
    options.second = undefined
  }

  // Format the date to local time using the user's timezone
  const localTime = new Intl.DateTimeFormat('en-GB', {
    ...options,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(date)

  // Calculate the difference in time
  const diffTime = currentDate.getTime() - date.getTime()
  const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
  const diffMinutes = Math.floor(diffTime / (1000 * 60))
  const diffSeconds = Math.floor(diffTime / 1000)

  // Return dynamically formatted output if enabled
  if (isDynamicFormat) {
    if (diffHours > 48) {
      return localTime
    } else if (diffHours > 24) {
      return 'Yesterday'
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`
    } else if (diffSeconds > 10) {
      return `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`
    } else {
      return 'Just now'
    }
  }

  return localTime
}

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

import useApi from '../../../services/useApi'
import {useContext} from 'react'
import {TicketDetailsContext} from '../context'

const useGetCommentsList = () => {
  const Api = useApi()
  const {ticketId} = useContext(TicketDetailsContext)

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/cards/${ticketId}/comments`,
    {
      queryId: 'ticket-comments-list',
      isCaching: false,
    },
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return {
    commentsList: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetCommentsList
